import React from 'react'
import './App.scss'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Image } from 'primereact/image'
import { InputSwitch } from 'primereact/inputswitch'
import { OverlayPanel } from 'primereact/overlaypanel'
//@ts-ignore
import logo from './img/shc_logo_ohne_Schriftzug.png'
import FileService from './services/FileService'
import { baseUri, createJob, deleteJob, getApiHealth, getJob } from './services/ApiService'
import Immutable from 'immutable'
import ReactJson from 'react-json-view'

const fileService = new FileService()

const finishedJobStatuses = ['completed', 'failed']

interface Job {
    id: string
    status: string
    imageBase64?: string
    result?: any
}

interface JobOptions {
    toleranceDetection: string
    roughnessDetection: string
    textParsing: string
    debug: string
}

const App = () => {
    const [serviceState, setServiceState] = React.useState({
        loading: false,
        healthy: true,
    })

    const [loading, setLoading] = React.useState(false)
    const [jobs, setJobs] = React.useState<Immutable.Map<string, Job>>(Immutable.Map())
    const [dialogContent, setDialogContent] = React.useState<React.ReactNode>(undefined)
    const [jobOptions, setJobOptions] = React.useState<JobOptions>({
        toleranceDetection: 'True',
        roughnessDetection: 'True',
        textParsing: 'True',
        debug: 'True',
    })

    const op = React.useRef<any>(null)

    React.useEffect(() => {
        setLoading(true)
        getApiHealth()
            .then(response => {
                if (response.status === 200) {
                    setServiceState({ loading: false, healthy: true })
                } else {
                    setServiceState({ loading: false, healthy: false })
                }
            })
            .catch(error => {
                console.log('error', error)
                setServiceState({ loading: false, healthy: false })
            })

            .finally(() => {
                setLoading(false)
            })
    }, [])

    React.useEffect(() => {
        const interval = setInterval(() => {
            jobs.filter(job => !finishedJobStatuses.includes(job.status)).forEach(job => {
                getJob(job.id)
                    .then(response => response.json())
                    .then(data => {
                        setJobs(state => state.set(job.id, { id: job.id, status: data.status, result: data?.result, imageBase64: data?.image }))
                    })
                    .catch(error => {
                        console.log('Error while getting job info', error)
                    })
            })
        }, 500)

        return () => clearInterval(interval)
    }, [jobs, setJobs, setLoading, console])

    const uploadImage = () => {
        const formData = new FormData()

        formData.append('ocr', 'paddle')
        formData.append('tolerance_detection', jobOptions.toleranceDetection)
        formData.append('roughness_detection', jobOptions.roughnessDetection)
        formData.append('text_parsing', jobOptions.textParsing)
        formData.append('debug', jobOptions.debug)

        setLoading(true)
        fileService
            .loadFile()
            .then(file => {
                createJob(file, formData)
                    .then(response => response.json())
                    .then(data => {
                        getJob(data.jobid)
                            .then(async response => {
                                try {
                                    const d = await response.json()
                                    setJobs(state =>
                                        state.set(data.jobid, { id: data.jobid, status: d.status, result: d?.result, imageBase64: d?.image })
                                    )
                                } catch (error) {
                                    setJobs(state => state.set(data.jobid, { id: data.jobid, status: 'error' }))
                                    console.log('Error while parsing job response', error)
                                }
                            })
                            .catch(error => {
                                console.log('Error while getting job info', error)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch(error => {
                        console.log('Error while creating job', error)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className='app'>
            <header className='header'>
                <div className='left'>
                    <img className='logo' src={logo} />
                    <h1>Data-Driven Engine CNC24</h1>
                </div>
                <div className='right'>
                    {!serviceState.loading && (
                        <>
                            {loading && <ProgressSpinner style={{ width: '2rem', height: '2rem' }} strokeWidth='6' animationDuration='.7s' />}

                            <span>
                                {serviceState.healthy ? (
                                    <Tag severity='success' value='Service Available' />
                                ) : (
                                    <Tag severity='danger' value='Service Unavailable' />
                                )}
                            </span>
                        </>
                    )}
                </div>
            </header>
            <main className='main'>
                {!serviceState.loading && serviceState.healthy && (
                    <>
                        <div className='upload-section'>
                            <Button label='Upload Image or PDF' onClick={uploadImage} />
                            <Button icon='pi pi-cog' rounded outlined severity='info' onClick={e => op?.current?.toggle(e)} />
                        </div>
                        <DataTable className='data-table' value={jobs.valueSeq().toArray()} tableStyle={{ minWidth: '50rem' }}>
                            <Column field='id' header='Id' />
                            <Column
                                field='status'
                                header='Status'
                                body={(job: Job) =>
                                    !finishedJobStatuses.includes(job.status) ? (
                                        <div className='job-status'>
                                            <span>{job.status}</span>
                                            <ProgressSpinner
                                                style={{ width: '1.5rem', height: '1.5rem', margin: '0.25rem' }}
                                                strokeWidth='6'
                                                animationDuration='.7s'
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span>{job.status}</span>
                                        </>
                                    )
                                }
                            />
                            <Column
                                header='Image'
                                body={(job: Job) => (
                                    <>
                                        {job.imageBase64 && (
                                            <Image src={`data:image/png;base64,${job.imageBase64}`} alt='Image' width='80' height='60' preview />
                                        )}
                                    </>
                                )}
                            />
                            <Column
                                header='Actions'
                                body={(job: Job) => (
                                    <>
                                        {job.result && (
                                            <Button
                                                label='Show Results'
                                                text
                                                onClick={e => {
                                                    setDialogContent(<ReactJson collapsed src={job.result} />)
                                                }}
                                            />
                                        )}
                                        <Button
                                            label='Delete Job'
                                            severity='danger'
                                            text
                                            onClick={e =>
                                                deleteJob(job.id)
                                                    .then(response => {
                                                        if (response.status === 200) {
                                                            setJobs(state => state.delete(job.id))
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log('Error while deleting job', error)
                                                    })
                                            }
                                        />
                                    </>
                                )}
                            />
                        </DataTable>
                        <Dialog
                            header='Header'
                            visible={!!dialogContent}
                            maximizable
                            onHide={() => setDialogContent(undefined)}
                            style={{ width: '50vw' }}
                            breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                            {dialogContent}
                        </Dialog>
                        <OverlayPanel ref={op}>
                            <div className='settings'>
                                <h4>Settings</h4>
                                <div className='field'>
                                    <label htmlFor='toleranceDetection'>Tolerance Detection</label>
                                    <InputSwitch
                                        id='toleranceDetection'
                                        checked={jobOptions.toleranceDetection === 'False' ? false : true}
                                        onChange={e =>
                                            setJobOptions(state => ({ ...state, toleranceDetection: e.value === false ? 'False' : 'True' }))
                                        }
                                    />
                                </div>
                                <div className='field'>
                                    <label htmlFor='roughnessDetection'>Roughness Detection</label>
                                    <InputSwitch
                                        id='roughnessDetection'
                                        checked={jobOptions.roughnessDetection === 'False' ? false : true}
                                        onChange={e =>
                                            setJobOptions(state => ({ ...state, roughnessDetection: e.value === false ? 'False' : 'True' }))
                                        }
                                    />
                                </div>
                                <div className='field'>
                                    <label htmlFor='textParsing'>Text Parsing</label>
                                    <InputSwitch
                                        id='textParsing'
                                        checked={jobOptions.textParsing === 'False' ? false : true}
                                        onChange={e => setJobOptions(state => ({ ...state, textParsing: e.value === false ? 'False' : 'True' }))}
                                    />
                                </div>
                                <div className='field'>
                                    <label htmlFor='debug'>Debug</label>
                                    <InputSwitch
                                        id='debug'
                                        checked={jobOptions.debug === 'False' ? false : true}
                                        onChange={e => setJobOptions(state => ({ ...state, debug: e.value === false ? 'False' : 'True' }))}
                                    />
                                </div>
                            </div>
                        </OverlayPanel>
                    </>
                )}
            </main>
            <footer className='footer'>
                <span>shouldcosting GmbH 2023 &copy;</span>
            </footer>
        </div>
    )
}

export default App
