export default class FileService {
    public async loadFile<T>(): Promise<any> {
        return await this.load()
    }

    private load(accept: string = 'image/*,.pdf') {
        return new Promise<string>((resolve, reject) => {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = accept

            input.onchange = (e: any) => {
                let file = e.target.files[0]
                resolve(file)
            }

            input.click()
        })
    }
}
