const headers = new Headers()
headers.append('Authorization', process.env.REACT_APP_API_KEY ?? '')

export const baseUri = process.env.REACT_APP_API_URL

export const getApiHealth = async () => {
    const requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow' as RequestRedirect,
    }

    return await fetch(`${baseUri}/api/health`, requestOptions)
}

export const createJob = async (file: Blob | string, formdata: FormData) => {
    formdata.append('file', file)

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow' as RequestRedirect,
    }

    return await fetch(`${baseUri}/api/v1/jobs/create`, requestOptions)
}

export const getJob = async (jobId: string) => {
    var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow' as RequestRedirect,
    }

    return await fetch(`${baseUri}/api/v1/jobs/${jobId}`, requestOptions)
}

export const deleteJob = async (jobId: string) => {
    var requestOptions = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow' as RequestRedirect,
    }

    return await fetch(`${baseUri}/api/v1/jobs/${jobId}`, requestOptions)
}

export const getImage = async (image: string) => {
    // Fetch the image.
    const response = await fetch(`${baseUri}${image}`, { headers })

    // Convert the data to Base64 and build a data URL.
    const binaryData = await response.arrayBuffer()
    const base64 = arrayBufferToBase64(binaryData)
    return `data:image/png;base64,${base64}`
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)))
}
